import * as React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";

const BlogIndex = ({ data, location }) => {
	const siteTitle = data.site.siteMetadata?.title || `Title`;

	return (
		<Layout location={location} title={siteTitle}>
			<Seo title="About" />
			<hr />
			<h2>会社情報</h2>
			<div className="about-contents-block-wrapper">
				<div className="about-contents-block">
					<span className="about-contents-block-title">会社名</span>
					<span className="about-contents-block-text">有限会社グリーンスタジオ GreenStudio</span>
					<span className="about-contents-block-title">設立</span>
					<span className="about-contents-block-text">2000 年 2 月 21 日</span>
					<span className="about-contents-block-title">代表者</span>
					<span className="about-contents-block-text">代表取締役　藤川　智</span>
					<span className="about-contents-block-title">事業内容</span>
					<span className="about-contents-block-text">
						◎広告・広報のメディアの企画・制作 及びデザイン
						<br />
						◎書籍・雑誌・Web 等の企画・編集・制作・デザイン これらを総合的に行います
					</span>
					<span className="about-contents-block-title">
						これまでの
						<br />
						主な受注先
					</span>
					<span className="about-contents-block-text">
						神奈川県営繕計画課 / 神奈川県議会 / 横浜市環境事業 局 / 横浜市消費生活総合センター
						<br />
						川崎市総務局 / 川崎市市民局広報課 / 川崎市まちづくり局 / 川崎市議会議会局
						<br />
						川崎市人事委員会事務局 / 川崎市川崎区 幸区 中原区 高津区 多摩区
						<br />
						相模原市商業観光課 / 相模原市南区 / 相模原商工会議所 / 相模原市産業振興財団
						<br />
						相模大野駅周辺商店会連合会 など
						<br />
						<br />
						*当社の基本情報に関するご照会は、お問合せください。
					</span>
				</div>
			</div>
			<hr />
			<h2>アクセス</h2>
			<div className="about-contents-block-wrapper">
				<div className="about-contents-block">
					<span className="about-contents-block-title">スタジオ</span>
					<span className="about-contents-block-text">
						〒252-0303　神奈川県相模原市南区相模大野 6-9-20　羽深ビル 302 号室
					</span>
					<span className="about-contents-block-title">Tel / Fax</span>
					<span className="about-contents-block-text">046-767-5915 (代)　/　042-767-5951</span>
					<span className="about-contents-block-title">営業時間</span>
					<span className="about-contents-block-text">9:00 ～ 17:00（土日祝日を除く）</span>
					<StaticImage className="about-contents-block-map" src="../images/map.jpg" quality={80} alt="地図" />
					駅からのアクセス　小田急線　相模大野駅　北口より徒歩 10 分
				</div>
			</div>
		</Layout>
	);
};

export default BlogIndex;

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
		allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
			nodes {
				excerpt
				fields {
					slug
				}
				frontmatter {
					date(formatString: "MMMM DD, YYYY")
					title
					description
				}
			}
		}
	}
`;
